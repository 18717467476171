.mediaContainer {
    position: relative;
}

.playBtnContainer {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playBtn {
    cursor: pointer;

    &:hover {
        opacity: 0.95;
    }

    &:active {
        opacity: 0.90;
    }
}