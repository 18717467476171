@import '@/styles/utils.scss';

.main {

  background-color: unset;
  height: 100%;

  @include mq(L) {
    background-color: rgba(gray, 0.1);
  }


  // >img {
  //   width: 100%;
  //   height: auto;
  //   max-height: 70vh;
  //   object-fit: contain;
  //   border-radius: 5px;
  //   @include container(100%);
  //   margin: auto;

  //   @include mq(L) {
  //     @include container(100%);
  //   }
  // }
}

.imageContainer {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @include mq(L) {
    margin-top: 50px;
    justify-content: space-evenly;
  }
}

.arrowIcon {
  border: none !important;
  cursor: pointer !important;
  transition: transform 0.3s;
  width: 33px;
  height: 33px;
  z-index: 2;

  @include mq(L) {
    box-shadow: 0px 1px 5px rgb(212, 205, 205);
    background-color: var(--arrow-similar-images) !important;
  }
}

.arrowIcon:hover {
  background-color: var(--arrow-similar-images) !important;
  transform: scale(1.1);

  @include mq(L) {
    box-shadow: 0px 1px 5px rgb(208, 200, 200) !important;
    transform: scale(1.2);
  }
}

.imageContainer img {
  max-width: 95%;
  height: auto;
}


.imageContainer img {
  max-width: 95%;

  height: auto;
}

.backBtn {
  background: none !important;
  cursor: pointer !important;
}

.innerContainer {
  position: relative;
  overflow: hidden;
}

.innerContainer {
  position: relative;
  display: flex;
  justify-content: center;

  &:active .beforeImg {
    -webkit-touch-callout: none;
    visibility: visible;
  }

  @include mq(L) {
    &:hover .beforeImg {
      visibility: visible;
    }
  }
}

.beforediv {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.mainImg:hover .beforediv {
  visibility: visible;
}

.mainImg:hover {
  -webkit-touch-callout: none;
}

.mainImg:active {
  -webkit-touch-callout: none;
}