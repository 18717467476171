@import '@/styles/utils.scss';

.main {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: white;
    box-shadow: 0px -18px 39px 28px  var(--view-more-background);
    background-color: var(--view-more-background);
    @include mq(M) {
        height: 150px;
    }
}

.viewMoreBtn {
    color: var(--view-more-button-hover-font-color) !important;
   
    &:hover {
        color: var(--view-more-button-hover-font-color) !important;
        cursor: pointer;
    }
}